.chime-effect {
    animation: chimeAnimation 0.5s ease-in-out;
}

@keyframes chimeAnimation {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

.bell-icon-container {
    position: relative;
    display: inline-block;
}

.notification-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0.2rem 0.4rem;
    font-size: 0.8rem;
    transform: translate(50%, -50%);
    z-index: 1;
}