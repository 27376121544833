.App {
    text-align: justify;
    font-size: small;
}
.divider1{
    width:200px;
    height:30px;
    display:inline-block;
}
.divider{
    width:5px;
    height:30px;
    display:inline-block;
}
.react-datepicker__month-container {
   font-size: x-small !important;
}

.modal-header {
    background-color: #00857C !important;
    color: white !important;
}

.modal-body {
    font-size: small;
}

.css-1ygcj2i-MuiTableCell-root{
    background-color: #00857C !important;
}

.css-aeeyth-MuiInputBase-input-MuiOutlinedInput-input {

    padding: 10px 1px !important;
}

.css-1ex1afd-MuiTableCell-root{
    padding: 1px !important;
}

.css-10dfkli-MuiTableCell-root{
    background-color: #00857C !important;
}

.MTableHeader-header-13{
    background-color: #00857C !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root{
    color: white !important;
    font-size: 11px !important;
    font-weight: bold !important;
    
}  


.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1230px !important;
}

.btn-primary {
    --bs-btn-bg: #00857C !important;
    --bs-padding: 10px;
}
.btn:hover {
    background-color: #00857C !important;
}

th{
    background-color: #00857C !important;
    color: white !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

th > div:nth-child(2){
    background-color: white !important;
    padding: 0 4px;
    margin-top: 5px;
    border: none;
    outline: none;
    border-radius: 2px;
    overflow: hidden;
}

th > div:nth-child(2) input{
    background-color: white !important;
    padding: 0;
    margin: 0;
    outline: none;
    border: none !important;
    width: 100%;
    padding-left: 5px;
}

th > div:nth-child(2) input::after{
   display: none !important;
}
th > div:nth-child(2) input:hover{
    border: none !important;
 }

th > div:nth-child(2) input::before{
    display: none !important;
 }

th > div:nth-child(2) div > span{
    display: none !important;
 }




thead.MuiTableHead-root button{
    color: white !important;
}

thead.MuiTableHead-root span{
    color: white !important;
}

.MuiTableSortLabel-icon{
    color: white !important;
}

#dropdown-item-button{
    color: white;
}

.btns{
    /* border: 1px solid red; */
    width: 100%;
    color: black;
    padding: 5px 10px;
    font-size: 11px;
    font-weight: 400;
}

button.btns:hover{
    background-color: rgba(0, 0, 0, 0.271) !important;
}

button.btns:active{
    background-color: #00857C !important;
    color: white;
}



.bulits{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 50px;
}

/* .MuiButtonBase-root{
    display: none !important;
} */

.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered{
    display: none;
}

table div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered{
    display: block;
}


.modal-header  button{
    color: white !important;
}

.modal-header button.btn-close{
    color: white !important;
    margin: 0 5px 0 0 ;
    background-color: white !important;
    position: relative;
    background-image: url('../../../public/close.png');
}

.MuiPaper-root *{
    font-size: 12px !important;
}


.MuiTablePagination-root{
    position: relative;
    top: -9px;
    right: 9px;
}

.css-uahusn {
    padding: 0px 0px 0px 0px !important;
}
.css-12fae6r {
    width: 25px !important;
}
.css-12fae6r {
    padding: 7.5px 20px 0px 7.5px !important;
}

.css-1xx5vs3 {
    padding: 7px 7px 7px 7px !important;
}
.css-kn0q8d {
     padding: 0px 0px 0px 0px !important;
}

.css-12fae6r {
    padding-top: 13px !important   
}
.css-uahusn span {
   padding: 7px !important
}

.css-kn0q8d {
    padding: 7px !important;
}
.css-19ed262-MuiTableCell-root {
    width: 35px !important;
    
}

.css-uqq48t-MuiTableCell-root {
    padding-left: 0.75rem !important;
}

