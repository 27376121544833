.form {
    margin: 'auto';
    width: '50%';
}

button{
  all: unset;
}

.ActionButtonStyle {
    margin: '0 auto';
    background-color: 'teal';
    color: 'white';
    padding: '.5rem .8rem';
    font-size: '.8em';
    border: 'black';
}


.review-container { 

    max-width: 800px; 
  
    margin: 0 auto; 
  
    padding: 20px; 
  
    border: 1px solid #ddd; 
  
    border-radius: 8px; 
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  
    font-family: Arial, sans-serif; 
  
  } 
  
   
  
  .review-title { 
  
    text-align: center; 
  
    font-size: 24px; 
  
    margin-bottom: 20px; 
  
  } 
  
   
  
  .info-section, .resource-section { 
  
    margin-bottom: 20px; 
  
  } 
  
   
  
  .info-row { 
  
    display: flex; 
  
    justify-content: space-between; 
  
    margin-bottom: 8px; 
  
  } 
  
   
  
  .info-label { 
  
    font-weight: bold; 
  
    margin-right: 5px; 
  
  } 
  
   
  
  .section-title { 
  
    font-size: 20px; 
  
    margin: 15px 0 10px; 
  
    border-bottom: 2px solid #ddd; 
  
    padding-bottom: 5px; 
  
  } 
  
   
  
  .resource-row { 
  
    display: flex; 
  
    justify-content: space-around; 
  
    margin-bottom: 10px; 
  
  } 
  
   
  
  .growth-table { 
  
    width: 40%; 
  
    border-collapse: collapse; 
  
    margin-top: 10px; 
  
  } 
  
   
  
  .growth-table th, .growth-table td { 
  
    padding: 8px; 
  
    text-align: center; 
  
    border: 1px solid #ddd; 
  
  } 
  
   
  
  .button-container { 
  
    display: flex; 
  
    justify-content: center; 
  
    gap: 10px; 
  
    margin-top: 20px; 
  
  } 
  
   
  
  .action-button { 
  
    padding: 10px 20px; 
  
    font-size: 16px; 
  
    cursor: pointer; 
  
    border: none; 
  
    border-radius: 4px; 
  
    transition: background-color 0.3s ease; 
  
  } 
  
   
  .submit { 
  
    background-color: #4CAF50; 
  
    color: white; 
  
  } 