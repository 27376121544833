table{
    border-collapse: collapse;
    width: 100%;
}

th, td {
    border: thin solid black;
    text-align: left;
}

th {
    background-color: lightseagreen;
    color: white;
}

tr:nth-child(even) {
    background-color: lightgrey;
}
tbody:nth-child(even) {
    background-color: lightgrey;
}

input[type="text"]{
    color: grey !important;
}
